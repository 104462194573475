import React from 'react';
import SEO from '../components/SEO';

export default function FourOhFourPage() {
  return (
    <>
      <SEO title="404: Not Found" />
      <p>Page Not Found, Broh!</p>
    </>
  );
}
